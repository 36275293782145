<template>
  <AntConfigProvider>
    <AntLayout class="layout">
      <Header class="header">
        <div>
          <UserIcon />
          {{ loginUserData.userId }}
          <AntTag>{{ PERMISSION_NAME[loginUserData.permission] }}</AntTag>
          <SettingIcon @click="onToggleDrawer" />
        </div>
        <AntButton class="logout" type="primary" @click="onClickLogout">Logout</AntButton>
      </Header>

      <AntLayout>
        <Sider
          :logoImg="require('@/assets/testerportal_logo.svg')"
          logoLink="/testerportal/player-list"
          :envList="sourceLineList"
          :envSelectedKey="selectedSourceLine.sourceLineId"
          :menuList="menuList"
          :menuOpenKeys="menuOpenKeys"
          :menuSelectedKeys="menuSelectedKeys"
          :onClickEnv="val => onChangeSelectedSourceLine(val.key)"
        >
          <template #env>
            <AntDropdown placement="bottom">
              <AntButton>{{ PERMISSION_NAME[selectedUserLevel] }}</AntButton>
              <template #overlay>
                <AntMenu @click="val => onChangeSelectedUserLevel(val.key)">
                  <template v-for="key in userLevelList" :key="key">
                    <AntMenuItem>
                      {{ PERMISSION_NAME[key] }}
                    </AntMenuItem>
                  </template>
                </AntMenu>
              </template>
            </AntDropdown>
          </template>
        </Sider>

      <AntLayout>
        <AntLayoutContent class="content">
          <slot></slot>
        </AntLayoutContent>

        <Footer :requestResponseData="requestResponseData" />
      </AntLayout>
    </AntLayout>
    </AntLayout>

    <AntDrawer
      title="User Settings"
      :width="720"
      :visible="drawerState.visible"
      :bodyStyle="{ paddingBottom: '80px' }"
      @close="onToggleDrawer"
    >
      <FormTemplate
        v-if="drawerState.visible"
        :visible="drawerState.data"
        :formFields="formFields"
        buttonText="Update"
        :onSubmit="onSubmit"
      />
      <AntAlert
        v-if="!!drawerState.alert.message"
        class="warning-message"
        :type="drawerState.alert.type"
        :message="drawerState.alert.message"
        showIcon
      />
    </AntDrawer>
  </AntConfigProvider>
</template>

<script>
import {
  h,
  ref,
  reactive,
  computed,
} from 'vue'

import { useRoute, useRouter } from 'vue-router'

import {
  ConfigProvider as AntConfigProvider,
  Layout as AntLayout,
  Menu as AntMenu,
  Tag as AntTag,
  Button as AntButton,
  Dropdown as AntDropdown,
  Drawer as AntDrawer,
  Alert as AntAlert,
  message as antMessage,
} from 'ant-design-vue'

import UserIcon from '@ant-design/icons-vue/lib/icons/UserOutlined'
import SettingIcon from '@ant-design/icons-vue/lib/icons/SettingFilled'
import TeamIcon from '@ant-design/icons-vue/lib/icons/TeamOutlined'
import HomeIcon from '@ant-design/icons-vue/lib/icons/HomeOutlined'
import DollarIcon from '@ant-design/icons-vue/lib/icons/DollarOutlined'
import AgentIcon from '@ant-design/icons-vue/lib/icons/ContactsOutlined'
import SolutionIcon from '@ant-design/icons-vue/lib/icons/SolutionOutlined'
import LockIcon from '@ant-design/icons-vue/lib/icons/LockOutlined'

import Sider from '@/components/AgentManagement/Layout/Sider'
import Header from '@/components/AgentManagement/Layout/Header'
import Footer from '@/components/AgentManagement/Layout/Footer'
import FormTemplate from '@/components/AgentManagement/FormTemplate'

import { PERMISSION_NAME } from '@/constants/testerPortal'

import { updateUser } from '@/api/testerportal'
import { logout } from '@/api/testerportal/login'

export default {
  name: 'Layout',
  components: {
    AntConfigProvider,
    AntLayout,
    AntLayoutContent: AntLayout.Content,
    AntMenu,
    AntMenuItem: AntMenu.Item,
    AntTag,
    AntButton,
    AntDropdown,
    AntDrawer,
    AntAlert,
    Sider,
    Header,
    Footer,
    FormTemplate,
    UserIcon,
    SettingIcon,
  },
  props: {
    selectedUserLevel: String,
    userLevelList: Array,
    selectedSourceLine: Object,
    sourceLineList: Array,
    loginPlayerData: Object,
    loginUserData: Object,
    permissionData: Object,
    requestResponseData: Object,
    onChangeSelectedUserLevel: Function,
    onChangeSelectedSourceLine: Function,
  },
  inheritAttrs: false,
  setup(props) {
    const router = useRouter()
    const route = useRoute()

    const menuOpenKeys = ref(['user'])
    const menuSelectedKeys = computed(() => [route.path.replace('/testerportal/', '')])

    const sourceLineList = computed(() => props.sourceLineList.map(({ source, line, sourceLineId }) => ({ key: `${sourceLineId}`, title: `${source.toUpperCase()} (${line} Line)` })))

    const menuList = computed(() => {
      const { permission } = props.loginUserData
      const res = []
      if (props.loginPlayerData.userId) {
        res.push({
          key: 'user',
          title: props.loginPlayerData.userId,
          icon: h(UserIcon),
          subMenu: [
            {
              key: 'enter-game',
              title: 'Enter Game',
              icon: h(HomeIcon),
              link: '/testerportal/enter-game',
            },
          ],
        })
      }

      if ((props.permissionData.PlayerList_View || []).includes(permission)) {
        res.push({
          key: 'player-list',
          title: 'Player List',
          icon: h(SolutionIcon),
          link: '/testerportal/player-list',
        })
      }

      if ((props.permissionData.RatioTable_View || []).includes(permission)) {
        res.push({
          key: 'ratio-table',
          title: 'Ratio Table',
          icon: h(DollarIcon),
          link: '/testerportal/ratio-table',
        })
      }

      if ((props.permissionData.UserAgentList_View || []).includes(permission)) {
        res.push({
          key: 'user-agent-list',
          title: 'User Agent List',
          icon: h(AgentIcon),
          link: '/testerportal/user-agent-list',
        })
      }

      if ((props.permissionData.UserList_View || []).includes(permission)) {
        res.push({
          key: 'user-list',
          title: 'User List',
          icon: h(TeamIcon),
          link: '/testerportal/user-list',
        })
      }

      if ((props.permissionData.UserLevelPermissions_View || []).includes(permission)) {
        res.push({
          key: 'user-level-permissions',
          title: 'User Level Permissions',
          icon: h(LockIcon),
          link: '/testerportal/user-level-permissions',
        })
      }

      return res
    })

    const onClickLogout = () => {
      logout()
      alert('You have been logged out of your account') // eslint-disable-line no-alert
      router.push('/testerportal/login')
    }

    const drawerState = reactive({ data: {}, alert: {} })

    const onToggleDrawer = () => {
      drawerState.visible = !drawerState.visible
      if (!drawerState.visible) {
        drawerState.data = {}
        drawerState.alert = {}
      }
    }

    const formFields = computed(() => [
      {
        id: 'userId',
        label: 'User ID',
        defaultValue: props.loginUserData.userId,
        element: 'input',
        inputType: 'text',
        maxlength: 21,
        disabled: true,
      },
      {
        id: 'permission',
        label: 'User Level',
        defaultValue: PERMISSION_NAME[props.loginUserData.permission],
        element: 'input',
        disabled: true,
      },
      {
        id: 'password',
        label: 'Reset Password',
        // defaultValue: drawerState.data.password,
        onBlur: e => drawerState.data.password = e.target.value,
        element: 'input-password',
        rules: [
          {
            required: true,
            message: 'Required',
          },
        ],
      },
      {
        id: 'confirmPassword',
        label: 'Confirm Password',
        element: 'input-password',
        rules: [
          {
            validator: async (rule, value) => {
              if (value && value !== drawerState.data.password) {
                return Promise.reject(new Error('Password do not match'))
              }
              return Promise.resolve()
            },
            trigger: 'blur',
          },
          {
            required: true,
            message: 'Required',
            trigger: ['blur', 'change'],
          },
        ],
      },
    ])

    const onSubmit = async (formState) => {
      const messageKey = Date.now()
      antMessage.loading('', { key: messageKey })

      const { data, message } = await updateUser({
        userId: props.loginUserData.userId,
        password: formState.password,
      })

      if (data) {
        antMessage.success({
          key: messageKey,
          content: `Reset user [${data.userId}] password successfully`,
        })
        logout()
        router.push('/testerportal/login')
      } else {
        drawerState.alert = {
          type: 'error',
          message,
        }
      }
    }

    return {
      sourceLineList, // eslint-disable-line vue/no-dupe-keys
      menuList,
      menuOpenKeys,
      menuSelectedKeys,
      PERMISSION_NAME,
      onClickLogout,
      formFields,
      drawerState,
      onToggleDrawer,
      onSubmit,
    }
  },
}
</script>

<style scoped>
.layout {
  height: 100vh;
  overflow: hidden;
}

.content {
  padding: 28px 36px;
  overflow-y: auto;
}

.content:has(.iframe-wrapper) {
  padding: 0px;
}

.header {
  display: flex;
  color: #fff;
  align-items: center;
  justify-content: flex-end;
  padding:  0 40px;
  background: #002140;
}

.header .ant-tag {
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
  margin-left: 4px;
}

.logout.ant-btn {
  margin-left: 32px;
}
</style>
